<template>
    <div>
        <rxNavBar title="选择房源"></rxNavBar>
        <div class="content" v-if="noContent"> <common-empty ></common-empty></div>
        <div class="content" v-else>
            <!-- 无合同时 -->
            <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
<!--                <van-list-->
<!--                        v-model="loading"-->
<!--                        :finished="finished"-->
<!--                        :finished-text="noContent? '':'已经到底了'"-->
<!--                        @load="this.onLoad"-->
<!--                >-->
                <div class="content_centre" v-for="(item,index) in dataList" :key="index"  @click="typeChoose(item.roomHouseId)">
                    <!-- 状态 -->
                     <div  class="content_title">{{item.contractStatusInfo}}</div>
                    <!-- 合同编号 -->
                    <div class="content_contract"><span>合同编号:</span><span>{{item.contractCode}}</span></div>
                    <!-- 内容 -->
                    <div class="content_span">{{item.roomDetailedAddress}}</div>
                </div>
<!--                </van-list>-->
            </van-pull-refresh>

        </div>
    </div>

</template>

<script>
    import {NavBar} from "vant";
    import  {contractList,userRepairAddressList} from "../../../getData/getData";
    import {globaluserId,getStaffId, responseUtil} from "../../../libs/rongxunUtil";
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    import Vue from 'vue';
    import { List } from 'vant';
    Vue.use(List);
    import { PullRefresh } from 'vant';
    Vue.use(PullRefresh);

    export default {
        name: "contractChoose",
        data(){
            return{
                dataList:[],
                pageCount:'',//总页数
                loading: false,
                finished: false,
                currentPage:'1',//当前页
                numberPage:'10',//每页条数
                type:'1',//租客合同类型
                isLoading: false,
                noContent:false,//显示空页面
                chooseType:'',//页面选择类型 1 维修 2 保洁
                dictionaryTitle:'',//维修类型
                id:''

            }
        },
        components:{
            [NavBar.name]:NavBar,
            [List.name]:List,
            [PullRefresh.name]:PullRefresh,
            rxNavBar
        },
        created() {
        this.chooseType=this.$route.query.changeType
        this.dictionaryTitle=this.$route.query.dictionaryTitle;//维修标题
        this.id=this.$route.query.id//维修状态码
            this.userRepairAddressList();
        },
        methods:{
            //页面跳转

            typeChoose(roomHouse_id){
                if (this.chooseType=="1"){
                    this.$router.push({
                        name: 'iWantRepair',
                        query: {
                            room_id:roomHouse_id,//页面判断跳转我要维修页面
                            dictionaryTitle:this.dictionaryTitle,//维修类型
                            id:this.id
                        },
                    })
                }else {
                    this.$router.push({
                        name: 'iWantCleaning',
                        query: {
                            room_id:roomHouse_id,//页面判断跳转我要维修页面
                        },
                    })
                }

            },

            onRefresh() {//刷新
                setTimeout(() => {
                    this.default();
                    this.isLoading = false;
                    this.userRepairAddressList()
                },800);
            },
            default(){//默认值
                this.dataList=[]
            },
            //房源选择初始化
            userRepairAddressList: function () {
                var that = this
                let initData = {
                    user_id:globaluserId(),
                }
                userRepairAddressList(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response)
                        if (response.data.code=='0'){
                            if (response.data.data.userRepairAddressList.length==0){
                                that.noContent=true;
                            } else {
                                var list=response.data.data.userRepairAddressList;
                                for (let i=0;i<list.length;i++){
                                    if (list[i].contractStatus=(0||1||2||3||6)){
                                        list[i].contractStatusInfo='租期中'
                                    }
                                }

                                that.dataList=list
                            }

                        }
                    })
                })
            },


    }

    }
</script>

<style scoped>

    .content{
        width: 100%;
        height: auto;
        margin-top: 30px;
    }
    .content_centre{
        width: 92%;
        height: auto;
        margin: 0 auto;
        border-radius: 8px;
        background-color: #ffffff;
        padding-bottom: 15px;
        margin-bottom: 15px;

    }
    .content_title{
        border-radius: 8px 1px;
        background-image: linear-gradient(to right,#FFAE82, #FB5F2E);
        width: 46px;
        height: 17px;
        border-bottom-left-radius: 0;
        font-size: 8px;
        color: rgba(255, 255, 255, 0.7);
        font-family: PingFangSC-Regular;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

    }
    /* 合同编号 */
    .content_contract{
        color: #EB8671;
        margin-top: 5px;
        margin-left: 15px;
        font-size: 15px;
        font-family: PingFangSC-Semibold;
    }
    .content_centre  div:not(:nth-of-type(1)){
        margin-left: 15px;
    }
    /* 内容 */
    .content_span{
        margin-top: 5px;
        color: rgba(34, 34, 34, 1);
        font-size: 15px;
        font-family: PingFangSC-Semibold;
    }

</style>
